export const translateOperator = {
  eq: "==",
  gt: ">",
  in: "array-contains-any",
  gte: ">=",
  lt: "<",
  lte: "<=",
  neq: "!=",
};

export function flattenFilters(filters) {
  return Object.keys(filters).reduce((acc, property) => {
    const filter = filters[property];
    const queries = Object.keys(filter).map((operator) => {
      return {
        property: property,
        operator: translateOperator[operator],
        value: filter[operator],
      };
    });
    const queriesWithValue = queries.filter((q) => {
      const isEmpty = q.value == null || q.value.length === 0;
      return !isEmpty;
    });
    return [...acc, ...queriesWithValue];
  }, []);
}

export function addQueriesToCollection(collection, queries) {
  return queries.reduce((acc, query) => {
    return acc.where(query.property, query.operator, query.value);
  }, collection);
}

export function generateQuery(collection, filters) {
  const queries = flattenFilters(filters);
  return addQueriesToCollection(collection, queries);
}
