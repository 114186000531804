export const sunConditions = [
  "DENSE_SHADE",
  "FULL_SHADE",
  "PARTIAL_SHADE",
  "LIGHT_SHADE",
  "SHADE",
  "FULL_SUN",
];

export const soilConditions = [
  "CLAY",
  "SILTY",
  "SANDY",
  "CHALKY",
  "PEATY",
  "LOAMY",
];

export const waterConditions = ["LOW", "MEDIUM", "HIGH"];

export const seedCategories = [
  "NUTS",
  "BEANS",
  "BEETS",
  "BROCCOLI_CAULIFLOUR",
  "CARROTS",
  "CORN",
  "COVER_CROPS",
  "CUCUMBERS",
  "TREE_FRUIT",
  "BERRIES",
  "GOURDS",
  "GRAINS",
  "GREENS",
  "HERBS_AND_SPICES",
  "MELONS",
  "ONIONS",
  "PEPPERS",
  "RADISH_AND_TURNIPS",
  "SQUASH",
  "TOMATOES",
];

export default {
  waterConditions,
  seedCategories,
  sunConditions,
  soilConditions,
};
