import reactifyWc from "./reactify-wc";
import geohash from "ngeohash";
// Calculate the upper and lower boundary geohashes for
// a given latitude, lngitude, and distance in miles
export const getGeohashRange = (latitude, lngitude, distance = 50) => {
  const lat = 0.0144927536231884; // degrees latitude per mile
  const lon = 0.0181818181818182; // degrees lngitude per mile

  const lowerLat = latitude - lat * distance;
  const lowerLon = lngitude - lon * distance;

  const upperLat = latitude + lat * distance;
  const upperLon = lngitude + lon * distance;

  const lower = geohash.encode(lowerLat, lowerLon);
  const upper = geohash.encode(upperLat, upperLon);

  return {
    lower,
    upper,
  };
};

export function throttle(callback, timeFrame) {
  var lastTime = 0;
  return function () {
    var now = new Date();
    if (now - lastTime >= timeFrame) {
      callback();
      lastTime = now;
    }
  };
}

// Return the true type of value
export function typeOf(value) {
  return Object.prototype.toString.call(value).slice(8, -1).toLowerCase();
}

export default { throttle, getGeohashRange, reactifyWc, typeOf };
