import { gql } from "@apollo/client";
import { flattenFilters, addQueriesToCollection } from "./utils";

export const typeDef = gql`
  extend type Query {
    seed(id: ID!): Seed!
    seeds(filter: SeedFilter): [Seed!]!
  }

  extend type Mutation {
    addSeed(input: AddSeedInput!): Seed!
    updateSeed(id: ID!, patch: UpdateSeedInput!): Seed!
    removeSeed(id: ID!): RemoveSeedPayload!
  }

  type Seed {
    id: ID!
    name: String!
    count: Int!
    soilConditions: [SoilCondition]
    waterConditions: [WaterCondition]
    sunConditions: [SunCondition]
    temperatureRange: TemperatureRange
    isPublic: Boolean!
    place: Place
    category: SeedCategory
  }

  type TemperatureRange {
    start: Int!
    end: Int!
  }

  input SeedFilter {
    id: String
    count: IntFilter
    geohash: StringFilter
    countryCode: StringFilter
    name: StringFilter
    category: StringFilter
    soilConditions: StringFilter
    sunConditions: StringFilter
  }

  input AddSeedInput {
    name: String!
    count: Int
    soilConditions: [SoilCondition]
    waterConditions: [WaterCondition]
    sunConditions: [SunCondition]
    temperatureRange: RangeInput
    isPublic: Boolean!
    category: SeedCategory
    price: Int
    placeId: ID!
  }

  input UpdateSeedInput {
    name: String
    count: Int
    isPublic: Boolean
    soilConditions: [SoilCondition]
    waterConditions: [WaterCondition]
    sunConditions: [SunCondition]
    temperatureRange: RangeInput
    category: SeedCategory
    placeId: ID
  }

  type RemoveSeedPayload {
    id: ID!
  }
`;

export const resolvers = {
  Query: {
    seed: async (root, args, ctx) => {
      try {
        const seed = await ctx.db.collection("seeds").doc(args.id).get();
        return { id: args.id, ...seed.data() };
      } catch (e) {
        throw new Error(e);
      }
    },
    seeds: async (root, args, ctx) => {
      try {
        const seeds = [];
        const seedPromises = [];

        const queryMap = flattenFilters(args.filter);
        const placeQueries = queryMap.filter((q) => q.property === "geohash");
        const seedQueries = queryMap.filter((q) => q.property !== "geohash");

        const placesRef = addQueriesToCollection(
          ctx.db.collection("places"),
          placeQueries
        );

        const places = await placesRef.get();

        places.forEach((place) => {
          const seedRef = addQueriesToCollection(
            ctx.db.collection("seeds"),
            seedQueries
          );

          seedPromises.push(
            seedRef
              .where("isPublic", "==", true)
              .where("placeId", "==", place.id)
              .get()
          );
        });

        const placesWithSeeds = await Promise.all(seedPromises);

        placesWithSeeds.forEach((seedsSnap) => {
          seedsSnap.forEach((seed) => {
            seeds.push({ id: seed.id, ...seed.data() });
          });
        });

        return seeds;
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  Mutation: {
    addSeed: async (root, args, ctx) => {
      try {
        var docRef = await ctx.db.collection("seeds").add({
          ...args.input,
        });
        return { id: docRef.id, ...args.input };
      } catch (e) {
        throw new Error(e);
      }
    },
    updateSeed: async (root, args, ctx) => {
      try {
        await ctx.db
          .collection("seeds")
          .doc(args.id)
          .update({ ...args.patch });
        const seedRef = await ctx.db.collection("seeds").doc(args.id).get();
        return { id: seedRef.id, ...seedRef.data() };
      } catch (e) {
        throw new Error(e);
      }
    },
    removeSeed: async (root, args, ctx) => {
      try {
        await ctx.db.collection("seeds").doc(args.id).delete();
        return { id: args.id };
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  Seed: {
    place: async (seed, args, ctx) => {
      try {
        if (!seed.placeId) return null;
        const item = await ctx.db.collection("places").doc(seed.placeId).get();
        // TODO: Why do we get item.id even if the place does not exist anymore
        if (!item.data()) return null;
        return { id: item.id, ...item.data() };
      } catch (e) {
        throw new Error(e);
      }
    },
  },
};
