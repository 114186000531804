import React, { useState } from "react";
import geohash from "ngeohash";
import * as Our from "./Core";
import "./MapSearchInput.css";
import { throttle } from "../utils";

export default function MapSearchInput({
  placeholder = "Search for a place",
  autofocus = false,
  size = "md",
  inputValue = undefined,
  onSelect = () => {},
  onInput = () => {},
}) {
  const [placeOptions, setPlaceOptions] = useState([]);

  async function getPlaces(searchString) {
    try {
      const res = await fetch(
        "https://places-de-1.algolianet.com/1/places/query?x-algolia-agent=Algolia%20for%20vanilla%20JavaScript%20(lite)%203.27.1%3BAlgolia%20Places%201.7.2&x-algolia-application-id=plQQRTS1QNIY&x-algolia-api-key=5edbd83cd85570af844d7bf964eaae3e",
        {
          method: "POST",
          body: JSON.stringify({ query: searchString }),
        }
      );
      const json = await res.json();
      setPlaceOptions(json.hits || []);
    } catch (e) {
      throw new Error(e);
    }
  }

  function handleSelect(e) {
    const selectedPlace = placeOptions.find(
      (place) => place.objectID === e.target.value
    );

    onSelect({
      id: selectedPlace.objectID,
      lat: selectedPlace._geoloc.lat,
      lng: selectedPlace._geoloc.lng,
      geohash: geohash.encode(
        selectedPlace._geoloc.lat,
        selectedPlace._geoloc.lng
      ),
      countryCode: selectedPlace.country_code,
      address:
        selectedPlace.locale_names.default[0] +
        ", " +
        selectedPlace.country.default,
    });
  }

  return (
    <Our.Select
      className="map-search-input"
      searchable
      autofocus={autofocus}
      full
      hide-arrow
      input-value={inputValue}
      placeholder={placeholder}
      onInput={(e) => {
        onInput(e);
        throttle(getPlaces(e.target.inputValue), 50);
      }}
      onSelect={handleSelect}
      size={size}
      searchable
    >
      <ion-icon name="search" slot="start"></ion-icon>
      {placeOptions.map((place) => {
        return (
          <Our.Option
            key={place.objectID}
            value={place.objectID}
            label={place.locale_names.default[0] + ", " + place.country.default}
          ></Our.Option>
        );
      })}
    </Our.Select>
  );
}
