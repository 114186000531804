import { makeExecutableSchema } from "graphql-tools";
import { SchemaLink } from "apollo-link-schema";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { auth, db, firestore } from "../services/firebase";

import {
  typeDef as PlaceDefs,
  resolvers as PlaceResolvers,
} from "./schema/Place";

import { typeDef as SeedDefs, resolvers as SeedResolvers } from "./schema/Seed";

import { typeDef as UserDefs, resolvers as UserResolvers } from "./schema/User";

import { typeDef as FilterDefs } from "./schema/Filters";
import { typeDef as EnumDefs } from "./schema/Enums";

const Query = `
  type Query {
    _empty: String
  }
`;

const Mutation = `
  type Mutation {
    _empty: String
  }
`;

const schema = makeExecutableSchema({
  typeDefs: [
    Query,
    Mutation,
    UserDefs,
    PlaceDefs,
    SeedDefs,
    FilterDefs,
    EnumDefs,
  ],
  resolvers: {
    Query: {
      ...UserResolvers.Query,
      ...SeedResolvers.Query,
      ...PlaceResolvers.Query,
    },
    Mutation: {
      ...UserResolvers.Mutation,
      ...SeedResolvers.Mutation,
      ...PlaceResolvers.Mutation,
    },
    User: UserResolvers.User,
    Place: PlaceResolvers.Place,
    Seed: SeedResolvers.Seed,
  },
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new SchemaLink({ schema, context: { auth, db, firestore } }),
});

export { client };
