import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Header.css";
import * as Our from "./Core";
import InputSignUp from "./InputSignUp";
import InputSignIn from "./InputSignIn";
import { SIGN_IN, SIGN_UP, ME } from "../graphql/mutations";
import { useMutation, gql, useLazyQuery, useQuery } from "@apollo/client";

export default function Header({ user }) {
  const [tab, setTab] = useState("signin");
  const [showModal, setShowModal] = useState(false);

  const [signIn, { error: signInError }] = useMutation(SIGN_IN);
  const [signUp, { error: signUpError }] = useMutation(SIGN_UP);

  return (
    <header className="header">
      <Our.Text className="logo" weight="600" look="p">
        <Link to="/">
          <span style={{ color: "var(--core-color-primary)" }}>Our</span> Seeds
        </Link>
      </Our.Text>
      <nav>
        <NavLink className="header__menu-item" exact to="/">
          <ion-icon name="home-outline"></ion-icon>
          <span>Home</span>
        </NavLink>
        <NavLink className="header__menu-item" exact to="/search">
          <ion-icon name="search-outline"></ion-icon>
          <span>Find</span>
        </NavLink>
        {user ? (
          <>
            <NavLink className="header__menu-item" to="/dashboard">
              <ion-icon name="grid-outline"></ion-icon>
              <span>Dashboard</span>
            </NavLink>
          </>
        ) : (
          <>
            <a
              className="header__menu-item"
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
              }}
            >
              <i className="gg-profile"></i>
              <span>Login</span>
            </a>
          </>
        )}
      </nav>
      <Our.Modal open={showModal} onToggle={(e) => setShowModal(e.target.open)}>
        <Our.Button
          rounded
          squared
          type="transparent"
          slot="header-end"
          onClick={() => setShowModal(false)}
        >
          <i className="gg-close"></i>
        </Our.Button>
        <Our.Container size="xxs" center>
          <Our.Box margin-y="lg">
            <Our.Tabs
              full
              position="center"
              value={tab}
              onChange={(e) => setTab(e.target.value)}
            >
              <Our.Tab value="signin">Login</Our.Tab>
              <Our.Tab value="signup">Sign Up</Our.Tab>
            </Our.Tabs>
          </Our.Box>
          {tab === "signup" && (
            <InputSignUp
              error={signUpError}
              onSubmit={(input) => {
                signUp({
                  variables: {
                    input,
                  },
                  refetchQueries: [{ query: ME }],
                }).then((res) => setShowModal(false));
              }}
            ></InputSignUp>
          )}
          {tab === "signin" && (
            <InputSignIn
              error={signInError}
              onSubmit={(input) => {
                signIn({
                  variables: {
                    input: {
                      email: input.email,
                      password: input.password,
                    },
                  },
                  refetchQueries: [{ query: ME }],
                }).then((res) => setShowModal(false));
              }}
            ></InputSignIn>
          )}
        </Our.Container>
      </Our.Modal>
    </header>
  );
}
