import React from "react";
import { useHistory } from "react-router";
import Header from "../../components/Header";
import * as Our from "../../components/Core";
import { auth, useSession } from "../../services/firebase";
import { gql, useQuery, useMutation } from "@apollo/client";
import { REMOVE_SEED_BOOKMARK, ME } from "../../graphql/mutations";

export default function Overview() {
  const userSession = useSession();
  const history = useHistory();
  const { data: userData, loading: loadingMyData } = useQuery(ME);

  return (
    <>
      <Our.Box margin-b="lg">
        <Our.Text weight="500" tag="h1">
          Overview
        </Our.Text>
      </Our.Box>
      <Our.Box margin-b="lg">
        <Our.Grid>
          <Our.GridItem sm="12" md="6">
            <Our.Box
              onClick={() => history.push("/dashboard/inventory")}
              depth="md"
              padding="lg"
            >
              <Our.Text tag="h2" look="h3">
                Inventory ({userData && userData.me.selectedPlace?.seeds.length}
                )
              </Our.Text>
            </Our.Box>
          </Our.GridItem>
          <Our.GridItem sm="12" md="6">
            <Our.Box
              onClick={() => history.push("/dashboard/saved")}
              depth="md"
              padding="lg"
            >
              <Our.Text tag="h2" look="h3">
                Saved
              </Our.Text>
            </Our.Box>
          </Our.GridItem>
        </Our.Grid>
      </Our.Box>
    </>
  );
}
