import { gql } from "@apollo/client";

export const typeDef = gql`
  enum SoilCondition {
    CLAY
    SILTY
    SANDY
    CHALKY
    PEATY
    LOAMY
  }

  enum SunCondition {
    FULL_SUN
    SHADE
    LIGHT_SHADE
    PARTIAL_SHADE
    FULL_SHADE
    DENSE_SHADE
  }

  enum WaterCondition {
    LOW
    MEDIUM
    HIGH
  }

  enum SeedCategory {
    NUTS
    BEANS
    BEETS
    BROCCOLI_CAULIFLOUR
    CARROTS
    CORN
    COVER_CROPS
    CUCUMBERS
    TREE_FRUIT
    BERRIES
    GOURDS
    GRAINS
    GREENS
    HERBS_AND_SPICES
    MELONS
    ONIONS
    PEPPERS
    RADISH_AND_TURNIPS
    SQUASH
    TOMATOES
  }
`;
