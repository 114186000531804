import React, { useState, useEffect } from "react";
import "./SidebarLayout.css";
import * as Our from "../components/Core";
import { useHistory } from "react-router";

export default function SidebarLayout({
  children,
  sidebar,
  sidebarButtonText = "",
  sidebarTitle = "",
}) {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    history.listen(() => {
      setShowModal(false);
    });
  }, []);

  return (
    <>
      <div className="sidebar-layout">
        <div className="sidebar-layout__sidebar">
          <div className="sidebar-layout__sidebar-content">
            {sidebarTitle && (
              <Our.Box padding-b="md">
                <Our.Text tag="h1" look="h4">
                  {sidebarTitle}
                </Our.Text>
              </Our.Box>
            )}
            {sidebar()}
          </div>
        </div>
        <div className="sidebar-layout__main">
          <Our.Box className="sidebar-layout__modal-trigger" margin-b="lg">
            <Our.Button
              type="transparent"
              rounded
              inline
              squared={sidebarButtonText ? false : true}
              onClick={() => setShowModal(true)}
            >
              <i className="gg-menu" slot={sidebarButtonText && "start"}></i>
              {sidebarButtonText}
            </Our.Button>
          </Our.Box>
          {children}
        </div>
      </div>
      <Our.Modal
        className="sidebar-layout__modal"
        open={showModal}
        onToggle={(e) => setShowModal(e.target.open)}
      >
        <Our.Text tag="h1" look="h3" slot="header-start">
          {sidebarTitle}
        </Our.Text>
        <div slot="header-end"></div>

        {sidebar()}
      </Our.Modal>
    </>
  );
}
