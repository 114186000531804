import React, { useState, useEffect } from "react";
import * as Our from "./Core";
import StepInput from "./StepInput";
import enums from "../constants/enums";
import { useInput } from "../hooks";

export default function InputSeed({
  title = "Add Seed",
  submitButtonText = "Save",
  resetOnSubmit = false,
  initialState = {
    name: "",
    count: 10,
    price: 0,
    isPublic: true,
    category: undefined,
    sunConditions: [],
    waterConditions: [],
    soilConditions: [],
    temperatureRange: { start: 10, end: 30 },
  },
  onChange = () => {},
  onSubmit = () => {},
  onDelete = () => {},
}) {
  const [isFree, setIsFree] = useState(true);

  const [waterConditionRange, setWaterConditionRange] = useState([
    0,
    enums.waterConditions.length - 1,
  ]);
  const [sunConditionRange, setSunConditionRange] = useState([
    0,
    enums.sunConditions.length - 1,
  ]);

  const { input, changeInput, submitInput } = useInput({
    initialInput: initialState,
    onChange,
    onSubmit,
    resetOnSubmit,
  });

  return (
    <>
      <Our.Box margin-b="lg">
        <Our.Text tag="h1">{title}</Our.Text>
      </Our.Box>
      <Our.Box margin-y="lg">
        <Our.Label>Name</Our.Label>
        <Our.Input
          value={input.name}
          onInput={(e) => changeInput({ name: e.target.value })}
          full
        >
          <div slot="error">Field is required</div>
        </Our.Input>
      </Our.Box>
      <Our.Box margin-y="lg">
        <Our.Label>Category</Our.Label>
        <Our.Select
          value={input.category}
          onChange={(e) =>
            changeInput({
              category: e.target.value,
            })
          }
          full
        >
          {enums.seedCategories.map((cat) => (
            <Our.Option value={cat} key={cat}>
              {cat}
            </Our.Option>
          ))}
        </Our.Select>
      </Our.Box>

      <Our.Box margin-y="lg">
        <Our.Label>Soil Conditions</Our.Label>
        <Our.Select
          multiple
          value={input.soilConditions}
          onChange={(e) =>
            changeInput({
              soilConditions: e.target.value,
            })
          }
          full
        >
          {enums.soilConditions.map((soil) => (
            <Our.Option value={soil} key={soil}>
              {soil}
            </Our.Option>
          ))}
        </Our.Select>
      </Our.Box>
      <Our.Box margin-y="lg">
        <Our.Label>Sun conditions</Our.Label>
        <Our.Range
          sensitivity="0.7"
          labels="Dense shade, Full sun"
          value={sunConditionRange}
          max={enums.sunConditions.length - 1}
          min="0"
          step="1"
          onChange={(e) => {
            const [start, end] = e.target.value;
            const conditions = enums.sunConditions.filter((c, i) => {
              return i >= start && i <= end;
            });
            changeInput({
              sunConditions: conditions,
            });
            setSunConditionRange([start, end]);
          }}
        ></Our.Range>
      </Our.Box>
      <Our.Box margin-y="lg">
        <Our.Label>Water conditions</Our.Label>
        <Our.Range
          sensitivity="0.7"
          labels="Dense shade, Full sun"
          value={waterConditionRange}
          max={enums.waterConditions.length - 1}
          min="0"
          step="1"
          onChange={(e) => {
            const [start, end] = e.target.value;
            const conditions = enums.waterConditions.filter((c, i) => {
              return i >= start && i <= end;
            });
            changeInput({
              waterConditions: conditions,
            });
            setWaterConditionRange([start, end]);
          }}
        ></Our.Range>
      </Our.Box>

      <Our.Box margin-y="lg">
        <Our.Label>Count</Our.Label>
        <StepInput
          value={input.count}
          onChange={(val) => changeInput({ count: val })}
        ></StepInput>
      </Our.Box>
      <Our.Box margin-y="lg">
        <Our.Toggle
          checked={input.isPublic}
          onChange={(e) => changeInput({ isPublic: e.target.checked })}
        >
          Public
        </Our.Toggle>
      </Our.Box>
      <Our.Box>
        <Our.Toggle
          checked={isFree}
          onChange={(e) => setIsFree(e.target.checked)}
        >
          Free
        </Our.Toggle>
      </Our.Box>
      {!isFree && (
        <Our.Box margin-y="lg">
          <Our.Label>Price</Our.Label>
          <Our.Input
            type="number"
            full
            inputmode="numeric"
            value={input.price}
            onChange={(e) => changeInput({ price: e.target.value })}
          >
            <span slot="end">USD</span>
          </Our.Input>
        </Our.Box>
      )}
      <Our.Box margin-y="lg">
        <Our.Button full type="primary" onClick={() => submitInput()}>
          {submitButtonText}
        </Our.Button>
      </Our.Box>
      {onDelete && (
        <Our.Box margin-y="lg">
          <Our.Button full type="danger" onClick={() => onDelete()}>
            Delete
          </Our.Button>
        </Our.Box>
      )}
    </>
  );
}
