import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { ApolloProvider } from "@apollo/client";
import { client } from "./graphql/index.js";
import { useAuth, UserContext } from "./services/firebase";

import Home from "./screens/Home";
import Dashboard from "./screens/Dashboard";
import Search from "./screens/Search";

import * as Our from "./components/Core";
import Spinner from "./components/Spinner";

function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
}

function App() {
  const { initializing, user } = useAuth();
  if (initializing) {
    return (
      <Our.Flex
        style={{ height: "100vh", textAlign: "center" }}
        align-items="center"
        justify-content="center"
      >
        <Our.Box>
          <Our.Text color="primary" tag="h1">
            Our Seeds
          </Our.Text>
          <Spinner></Spinner>
        </Our.Box>
      </Our.Flex>
    );
  }

  return (
    <div className="App">
      <UserContext.Provider value={{ user }}>
        <ApolloProvider client={client}>
          <Router>
            <Switch>
              <Route exact path="/" component={Home}></Route>
              <Route exact path="/search" component={Search}></Route>
              <PrivateRoute
                isAuthenticated={user}
                path="/dashboard"
                component={Dashboard}
              ></PrivateRoute>
            </Switch>
          </Router>
        </ApolloProvider>
      </UserContext.Provider>
    </div>
  );
}

export default App;
