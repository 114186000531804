import React, { useState } from "react";
import * as Our from "./Core";
import MapSearchInput from "./MapSearchInput";

export default function InputSignUp({
  initialState = {
    email: "",
    password: "",
  },
  error = null,
  resetOnSubmit = false,
  onSubmit = () => {},
  submitButtonText = "Sign up",
}) {
  const [input, setInput] = useState(initialState);

  function handleSetInput(newInput) {
    setInput(newInput);
  }

  function handleOnSubmit() {
    onSubmit(input);
    if (resetOnSubmit) setInput(initialState);
  }

  return (
    <>
      <Our.Container size="xs" center>
        <Our.Box margin-y="md">
          <Our.Label for="email">Email</Our.Label>
          <Our.Input
            id="email"
            autoComplete="email"
            value={input.email}
            onInput={(e) => handleSetInput({ ...input, email: e.target.value })}
            full
            type="email"
            placeholder=""
          ></Our.Input>
        </Our.Box>
        <Our.Box margin-y="md">
          <Our.Label for="new-password">Password</Our.Label>
          <Our.Input
            id="new-password"
            name="new-password"
            value={input.password}
            onInput={(e) =>
              handleSetInput({ ...input, password: e.target.value })
            }
            full
            type="password"
            autoComplete="new-password"
            placeholder=""
          ></Our.Input>
        </Our.Box>
        {error && <Our.Text color="danger">{error.message}</Our.Text>}
        <Our.Box margin-y="xl">
          <Our.Button full type="primary" onClick={() => handleOnSubmit()}>
            {submitButtonText}
          </Our.Button>
        </Our.Box>
      </Our.Container>
    </>
  );
}
