import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { auth, useSession } from "../../services/firebase";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { SET_SELECTED_PLACE, ME } from "../../graphql/mutations";

import Inventory from "./Inventory";
import Saved from "./Saved";
import Overview from "./Overview";
import Settings from "./Settings";
import EditSeed from "./EditSeed";
import AddSeed from "./AddSeed";
import AddPlace from "./AddPlace";

import LoaderContainer from "../../components/LoaderContainer";

import SidebarLayout from "../../layouts/SidebarLayout";

import Header from "../../components/Header";
import * as Our from "../../components/Core";

export default function MyAccount() {
  const history = useHistory();
  const userSession = useSession();

  const [showPlaceOptions, setShowPlaceOptions] = useState(false);

  const [darkMode, setDarkMode] = useState(document.body.hasAttribute("mode"));

  const [setSelectedPlace] = useMutation(SET_SELECTED_PLACE);

  const { data: userData, loading: loadingUser, error: userError } = useQuery(
    ME
  );

  function toggleMode(checked) {
    if (!checked) {
      setDarkMode(false);
      document.body.removeAttribute("mode");
    } else {
      document.body.setAttribute("mode", "dark");
      setDarkMode(true);
    }
  }

  function Sidebar() {
    const hasSelectedPlace = userData?.me.selectedPlace;
    const hasAnyPlaces = userData?.me.places.length > 0;

    return (
      <>
        {userData && hasSelectedPlace && hasAnyPlaces && (
          <>
            <Our.Overlay
              open={showPlaceOptions}
              onToggle={(e) => {
                setShowPlaceOptions(e.target.open);
              }}
              style={{ width: "100%" }}
            >
              <Our.Button
                style={{ justifyContent: "space-between" }}
                full
                slot="trigger"
              >
                {userData.me.selectedPlace?.name}
                <i slot="end" className="gg-chevron-down"></i>
              </Our.Button>
              <Our.Box
                bg="white"
                style={{ minWidth: "240px", width: "100%" }}
                depth="sm"
                slot="content"
              >
                <Our.List selectable>
                  {userData.me.places.map((place) => {
                    const selected = userData.me.selectedPlace?.id === place.id;
                    return (
                      <Our.ListItem
                        key={place.id}
                        selected={selected}
                        onClick={() => {
                          setSelectedPlace({
                            variables: {
                              patch: { selectedPlaceId: place.id },
                            },
                            refetchQueries: [{ query: ME }],
                          });
                          setShowPlaceOptions(false);
                        }}
                      >
                        {selected && <i className="gg-check" slot="start"></i>}
                        {place.name}
                      </Our.ListItem>
                    );
                  })}
                </Our.List>
                <hr></hr>
                <Our.List selectable>
                  <Our.ListItem
                    onClick={() => history.push("/dashboard/add-place")}
                  >
                    Add place
                    <i className="gg-math-plus" slot="end"></i>
                  </Our.ListItem>
                </Our.List>
              </Our.Box>
            </Our.Overlay>
            <Our.Box margin-y="md">
              <Our.List selectable>
                <Our.ListItem onClick={() => history.push("/dashboard")}>
                  <ion-icon slot="start" name="grid-outline"></ion-icon>
                  Overview
                </Our.ListItem>
                <Our.ListItem
                  onClick={() => history.push("/dashboard/inventory")}
                >
                  <ion-icon slot="start" name="list-outline"></ion-icon>
                  Inventory
                </Our.ListItem>
                <Our.ListItem onClick={() => history.push("/dashboard/saved")}>
                  <ion-icon slot="start" name="bookmark-outline"></ion-icon>
                  Saved
                </Our.ListItem>
                <Our.ListItem
                  onClick={() => {
                    history.push("/dashboard/settings");
                  }}
                >
                  <ion-icon slot="start" name="settings-outline"></ion-icon>
                  Settings
                </Our.ListItem>
              </Our.List>
            </Our.Box>
            <hr></hr>
          </>
        )}
        <Our.Box margin-y="md" padding-x="md">
          <Our.Flex>
            <div>
              <ion-icon slot="start" name="person-circle-outline"></ion-icon>
            </div>
            <Our.Box padding-l="sm">
              <Our.Text color="font-light" size="md">
                {userSession.displayName}
              </Our.Text>
              <Our.Text color="font-light" look="small">
                {userSession.email}
              </Our.Text>
            </Our.Box>
          </Our.Flex>
        </Our.Box>
        <Our.List selectable>
          <Our.ListItem onClick={() => auth.signOut()}>Log out</Our.ListItem>
        </Our.List>
      </>
    );
  }

  const hasPlaces = userData && userData.me.places.length > 0;

  return (
    <>
      <Header user={userSession} onSignOut={() => auth.signOut()}></Header>
      <Our.Box padding-x="lg" padding-y="lg">
        <SidebarLayout sidebar={Sidebar} sidebarButtonText="Account">
          <LoaderContainer loading={loadingUser} error={userError}>
            <Switch>
              <Route path="/dashboard/add-place">
                <AddPlace></AddPlace>
              </Route>
              {hasPlaces ? (
                <>
                  <Route exact path="/dashboard">
                    <Overview></Overview>
                  </Route>
                  <Route path="/dashboard/inventory">
                    <Inventory></Inventory>
                  </Route>
                  <Route path="/dashboard/saved" component={Saved}></Route>
                  <Route path="/dashboard/add-seed">
                    <AddSeed></AddSeed>
                  </Route>
                  <Route path="/dashboard/settings">
                    <Settings></Settings>
                  </Route>
                  <Route path="/dashboard/edit-seed/:id">
                    <EditSeed></EditSeed>
                  </Route>
                </>
              ) : (
                <Route exact path="/dashboard">
                  <div>
                    You have no places. Please add a place
                    <Our.Button
                      onClick={() => history.push("/dashboard/add-place")}
                    >
                      Add
                    </Our.Button>
                  </div>
                </Route>
              )}
            </Switch>
          </LoaderContainer>
        </SidebarLayout>
      </Our.Box>
      {userData && !userData.me.selectedPlace && userData.me.places.length > 0 && (
        <Our.Modal open={true}>
          <Our.Box margin-b="lg">
            <Our.Text look="h3">Please select place</Our.Text>
          </Our.Box>
          <Our.List selectable>
            {userData.me.places.map((place) => (
              <Our.ListItem
                onClick={() =>
                  setSelectedPlace({
                    variables: { patch: { selectedPlaceId: place.id } },
                  })
                }
              >
                <Our.Box margin-y="md">
                  <Our.Text no-margin tag="h4">
                    {place.name}
                  </Our.Text>
                  <Our.Text tag="p">{place.address}</Our.Text>
                </Our.Box>
              </Our.ListItem>
            ))}
            <Our.ListItem onClick={() => history.push("/dashboard/add-place")}>
              Add place
              <i className="gg-math-plus" slot="end"></i>
            </Our.ListItem>
          </Our.List>
        </Our.Modal>
      )}
    </>
  );
}
