import React from "react";
import * as Our from "./Core";
import "./SeedCard.css";

export default function SeedCard({
  isAuthenticated = false,
  seed = { name: "", place: { address: "" } },
  onRemoveBookmark = () => {},
  onAddBookmark = () => {},
  isSaved = false,
}) {
  return (
    <Our.Box className="seed-card" margin-b="md" depth="sm">
      <Our.Flex>
        {isAuthenticated && (
          <Our.Checkbox
            className="bookmark"
            checked={isSaved}
            onChange={(e) =>
              e.target.checked
                ? onAddBookmark(seed.id)
                : onRemoveBookmark(seed.id)
            }
          >
            <i style={{ "--ggs": 1.3 }} className="gg-bookmark" slot="box"></i>
          </Our.Checkbox>
        )}
        <img
          alt="seed"
          style={{ objectFit: "cover", height: "150px", width: "30%" }}
          src="http://placeimg.com/640/480/nature"
        ></img>
        <Our.Box padding="lg">
          <Our.Text full tag="h2" look="h3">
            {seed.name}
          </Our.Text>
          <Our.Text full>{seed.place.address}</Our.Text>
        </Our.Box>
      </Our.Flex>
    </Our.Box>
  );
}
