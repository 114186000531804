import { createContext, useContext, useState, useEffect } from "react";
import firebase from "firebase/app";
import * as firebaseui from "firebaseui";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCXjXNWozNF8sHatOx0zqXwtVAgPmx-rug",
  authDomain: "our-seeds.firebaseapp.com",
  databaseURL: "https://our-seeds.firebaseio.com",
  projectId: "our-seeds",
  storageBucket: "our-seeds.appspot.com",
  messagingSenderId: "810305458938",
  appId: "1:810305458938:web:4467fb20b5434a02866f43",
  measurementId: "G-MEZ9D4QTYJ",
};

const firestore = firebase.firestore;

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

const auth = firebase.auth();

const ui = new firebaseui.auth.AuthUI(firebase.auth());

const uiConfig = {
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  signInFlow: "popup",
  signInSuccessUrl: "/",
  signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
};

const useAuth = () => {
  const [state, setState] = useState(() => {
    const user = firebase.auth().currentUser;
    return { initializing: !user, user };
  });

  function onChange(user) {
    setState({ initializing: false, user });
  }

  useEffect(() => {
    // listen for auth state changes
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange);
    // unsubscribe to the listener when unmounting
    return () => unsubscribe();
  }, []);

  return state;
};

const UserContext = createContext({
  user: null,
});

const useSession = () => {
  const { user } = useContext(UserContext);
  return user;
};

export { db, ui, uiConfig, firestore, useAuth, useSession, UserContext, auth };
