import React from "react";
import Header from "../../components/Header";
import * as Our from "../../components/Core";
import { auth, useSession } from "../../services/firebase";
import { gql, useQuery, useMutation } from "@apollo/client";
import { REMOVE_SEED_BOOKMARK, ME } from "../../graphql/mutations";

import SeedCard from "../../components/SeedCard";
import Spinner from "../../components/Spinner";

export default function MyAccount() {
  const userSession = useSession();

  const { data: myData, loading: loadingMyData } = useQuery(ME);

  const [removeBookmark] = useMutation(REMOVE_SEED_BOOKMARK);

  return (
    <>
      <Our.Box margin-b="lg">
        <Our.Text weight="500" tag="h1">
          Saved
        </Our.Text>
      </Our.Box>

      <Our.Box margin-y="lg">
        {loadingMyData && (
          <Our.Flex justify-content="center" align-items="center">
            <Spinner></Spinner>
          </Our.Flex>
        )}
        {myData && !myData.me.savedSeeds.length && (
          <div>No bookmarks yet..</div>
        )}
        {myData?.me.savedSeeds.map((seed) => (
          <SeedCard
            isAuthenticated={userSession.uid}
            isSaved={true}
            onRemoveBookmark={() => {
              removeBookmark({
                variables: {
                  id: seed.id,
                },
                optimisticResponse: {
                  __typename: "Mutation",
                  removeSeedBookmark: {
                    id: seed.id,
                    __typename: "Seed",
                  },
                },
                update(cache, { data: { removeSeedBookmark } }) {
                  const { me } = cache.readQuery({
                    query: ME,
                    variables: { id: userSession.uid },
                  });
                  cache.writeQuery({
                    query: ME,
                    variables: { id: userSession.uid },
                    data: {
                      me: {
                        ...me,
                        savedSeeds: me.savedSeeds.filter(
                          (b) => b.id !== removeSeedBookmark.id
                        ),
                      },
                    },
                  });
                },
              });
            }}
            key={seed.id}
            seed={seed}
          ></SeedCard>
        ))}
      </Our.Box>
    </>
  );
}
