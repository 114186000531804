import React, { useState } from "react";
import * as Our from "./Core";

export default function InputSignIn({
  initialState = {
    email: "",
    password: "",
  },
  resetOnSubmit = false,
  isLoading = false,
  error = null,
  onSubmit = () => {},
  submitButtonText = "Log in",
}) {
  const [input, setInput] = useState(initialState);

  function handleSetInput(newInput) {
    setInput(newInput);
  }

  function handleOnSubmit() {
    onSubmit(input);
    if (resetOnSubmit) setInput(initialState);
  }

  return (
    <>
      <Our.Container size="xs" center>
        <form>
          <Our.Box margin-y="md">
            <Our.Label for="email">Email</Our.Label>
            <Our.Input
              id="email"
              name="email"
              autoComplete="email"
              value={input.email}
              onInput={(e) =>
                handleSetInput({ ...input, email: e.target.value })
              }
              full
              type="email"
            ></Our.Input>
          </Our.Box>
          <Our.Box margin-y="md">
            <Our.Label for="current-password">Password</Our.Label>
            <Our.Input
              name="current-password"
              id="current-password"
              value={input.password}
              autoComplete="current-password"
              type="password"
              onInput={(e) =>
                handleSetInput({ ...input, password: e.target.value })
              }
              onKeydown={(e) => {
                if (e.keyCode === 13) {
                  handleOnSubmit();
                }
              }}
              full
            ></Our.Input>
          </Our.Box>
          {error && <Our.Text color="danger">{error.message}</Our.Text>}
          <Our.Box margin-y="xl">
            <Our.Button full type="primary" onClick={() => handleOnSubmit()}>
              {submitButtonText}
            </Our.Button>
          </Our.Box>
        </form>
      </Our.Container>
    </>
  );
}
