import React, { useState, useCallback, useEffect } from "react";
import * as Our from "../components/Core";
import Header from "../components/Header";
import Spinner from "../components/Spinner";
import SeedCard from "../components/SeedCard";
import { getGeohashRange } from "../utils";
import { useGeoIp, useRouteQuery } from "../hooks";
import { useSession, auth } from "../services/firebase";
import { useHistory } from "react-router";
import { useQuery, useLazyQuery, gql, useMutation } from "@apollo/client";
import mapboxgl from "mapbox-gl/dist/mapbox-gl.js";
import LoaderContainer from "../components/LoaderContainer";
import MapSearchInput from "../components/MapSearchInput";
import InputFilterSeeds from "../components/InputFilterSeeds";

mapboxgl.accessToken =
  "pk.eyJ1IjoibGVpZnJpa3NoZWltIiwiYSI6ImNrYThza2VibzAyd3cycXBqZjRpNjdlc2MifQ.ohHSpM7z_MU_NLEsJ1r2lQ";

const ME = gql`
  query Me {
    me {
      savedSeeds {
        id
      }
    }
  }
`;

const SEEDS = gql`
  query Seeds($filter: SeedFilter!) {
    seeds(filter: $filter) {
      id
      name
      place {
        lat
        lng
        address
      }
    }
  }
`;

const ADD_SEED_BOOKMARK = gql`
  mutation addSeedBookmark($id: ID!) {
    addSeedBookmark(id: $id) {
      id
    }
  }
`;

const REMOVE_SEED_BOOKMARK = gql`
  mutation removeSeedBookmark($id: ID!) {
    removeSeedBookmark(id: $id) {
      id
    }
  }
`;

async function geoIpLocation() {
  try {
    const res = await fetch(" https://freegeoip.app/json/");
    return await res.json();
  } catch (e) {
    throw new Error(e);
  }
}

function onMapLoad() {
  //  this is map instance
  this.resize();
}

export default function SearchResults() {
  const userSession = useSession();
  const history = useHistory();
  const query = useRouteQuery();

  // get location query params if set
  const latQuery = query.has("lat") && parseFloat(query.get("lat"));
  const lngQuery = query.has("lng") && parseFloat(query.get("lng"));

  // on mobile show list mode as default
  const [viewMode, setViewMode] = useState("list");

  // the mapbox map instance
  const [mapInstance, setMapInstance] = useState(null);

  // value in the mapsearch
  const [searchValue, setSearchValue] = useState(query.get("q"));

  // show filters modal to narrow down search
  const [showFilters, setShowFilters] = useState(false);

  const [addBookmark] = useMutation(ADD_SEED_BOOKMARK);
  const [removeBookmark] = useMutation(REMOVE_SEED_BOOKMARK);

  const { data: myData } = useQuery(ME);

  const [getSeeds, { data: seedData, loading: loadingSeeds }] = useLazyQuery(
    SEEDS
  );

  function onMapMove() {
    // this is map instance
    const mapLng = this.getCenter().lng.toFixed(4);
    const mapLat = this.getCenter().lat.toFixed(4);
    const mapZoom = this.getZoom().toFixed(2);
    query.set("lat", mapLat);
    query.set("lng", mapLng);
    // TODO: Handle move map but don't search until asked
    //history.push(`/results?&lat=${mapLat}&lng=${mapLng}`);
  }

  //
  const mapRef = useCallback((node) => {
    if (node !== null) {
      // default map options
      let mapOptions = {
        container: node,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [lngQuery, latQuery],
        zoom: 10,
      };
      async function setMap() {
        // if no search params in url we get the location
        //of the user based on IP the Address
        if (!latQuery && !lngQuery) {
          try {
            const { latitude, longitude } = await geoIpLocation();
            mapOptions = { ...mapOptions, center: [longitude, latitude] };
          } catch (e) {
            throw new Error(e);
          }
        }
        // initialize the map and add event listeners
        const map = new mapboxgl.Map(mapOptions);
        map.on("load", onMapLoad);
        map.on("move", onMapMove);
        setMapInstance(map);
        // get all seeds within location range
        getSeeds({
          variables: {
            filter: {
              geohash: {
                gte: getGeohashRange(mapOptions.center[1], mapOptions.center[0])
                  .lower,
                lte: getGeohashRange(mapOptions.center[1], mapOptions.center[0])
                  .upper,
              },
            },
          },
        });
      }
      setMap();
    }
    return () => {
      // remove listeners when screen is not mounted
      mapInstance.off("load", onMapLoad);
      mapInstance.off("moveend", onMapMove);
    };
  }, []);

  function handleSelectPlace(place) {
    history.push(
      `/search?&q=${place.address}&lat=${place.lat}&lng=${place.lng}`
    );
  }

  // If lat or lng changes in the url eg if user searches for another place
  // we refetch the seeds
  // TODO: We should keep the filter values from the modal so if the user
  // is searching for a new place they still keep the filter values
  useEffect(() => {
    if (mapInstance) {
      mapInstance.setZoom(10);
      mapInstance.setCenter([lngQuery, latQuery]);
      getSeeds({
        variables: {
          filter: {
            geohash: {
              gte: getGeohashRange(latQuery, lngQuery).lower,
              lte: getGeohashRange(latQuery, lngQuery).upper,
            },
          },
        },
      });
    }
  }, [latQuery, lngQuery]);

  // Apply markers to the map when we get new seed results
  // TODO: Remove previous markers for a new search
  useEffect(() => {
    if (seedData && mapInstance) {
      seedData.seeds.map((seed) => {
        const marker = new mapboxgl.Marker()
          .setLngLat([seed.place.lng, seed.place.lat])
          .addTo(mapInstance);
      });
    }
  }, [JSON.stringify(seedData), mapInstance]);

  useEffect(() => {
    if (viewMode === "map") {
      mapInstance.resize();
    }
  }, [viewMode]);

  return (
    <>
      <Header user={userSession} onSignOut={() => auth.signOut()}></Header>

      <Our.Tabs
        value={viewMode}
        onChange={(e) => setViewMode(e.target.value)}
        className="view-mode"
      >
        <Our.Button value="list" size="sm" rounded type="secondary">
          List
        </Our.Button>
        <Our.Button value="map" size="sm" rounded type="secondary">
          Map
        </Our.Button>
      </Our.Tabs>

      <Our.Container center size="full">
        <>
          <Our.Grid gap="sm">
            <Our.GridItem sm={viewMode === "list" ? "12" : "none"} md="5">
              <Our.Box
                style={{
                  position: "relative",
                  overflowY: "scroll",
                  height: "calc(100vh - var(--header-height))",
                }}
                padding-x="md"
              >
                <Our.Box margin-y="sm">
                  <MapSearchInput
                    inputValue={searchValue}
                    onInput={(e) => {
                      setSearchValue(e.target.inputValue);
                    }}
                    onSelect={(place) => {
                      handleSelectPlace(place);
                    }}
                  ></MapSearchInput>
                </Our.Box>
                <Our.Box inline margin-r="sm">
                  <Our.Button
                    rounded
                    size="sm"
                    type="secondary"
                    onClick={() => setShowFilters(!showFilters)}
                  >
                    Filters
                    <i
                      style={{ "--ggs": 0.6 }}
                      className="gg-options"
                      slot="end"
                    ></i>
                  </Our.Button>
                </Our.Box>
                <Our.Box inline margin-r="sm">
                  <Our.Button rounded size="sm" type="secondary">
                    Save search
                    <i
                      style={{ "--ggs": 0.6 }}
                      className="gg-bell"
                      slot="end"
                    ></i>
                  </Our.Button>
                </Our.Box>

                <Our.Box margin-y="lg">
                  <LoaderContainer loading={loadingSeeds}>
                    {seedData?.seeds.length ? (
                      seedData.seeds.map((seed) => {
                        const isSaved = myData?.me.savedSeeds.some(
                          (s) => s.id === seed.id
                        );
                        return (
                          <SeedCard
                            isAuthenticated={userSession?.uid}
                            isSaved={isSaved}
                            onRemoveBookmark={() => {
                              removeBookmark({
                                variables: {
                                  id: seed.id,
                                },
                                optimisticResponse: {
                                  __typename: "Mutation",
                                  removeSeedBookmark: {
                                    id: seed.id,
                                    __typename: "Seed",
                                  },
                                },
                                update(
                                  cache,
                                  { data: { removeSeedBookmark } }
                                ) {
                                  const { me } = cache.readQuery({
                                    query: ME,
                                    variables: { id: userSession?.uid },
                                  });
                                  cache.writeQuery({
                                    query: ME,
                                    variables: { id: userSession?.uid },
                                    data: {
                                      me: {
                                        ...me,
                                        savedSeeds: me.savedSeeds.filter(
                                          (b) => b.id !== removeSeedBookmark.id
                                        ),
                                      },
                                    },
                                  });
                                },
                              });
                            }}
                            onAddBookmark={() => {
                              addBookmark({
                                variables: {
                                  id: seed.id,
                                },
                                optimisticResponse: {
                                  __typename: "Mutation",
                                  addSeedBookmark: {
                                    id: seed.id,
                                    __typename: "Seed",
                                  },
                                },
                                update(cache, { data: { addSeedBookmark } }) {
                                  const { me } = cache.readQuery({
                                    query: ME,
                                  });
                                  cache.writeQuery({
                                    query: ME,
                                    data: {
                                      me: {
                                        ...me,
                                        savedSeeds: [
                                          ...me.savedSeeds,
                                          { ...addSeedBookmark },
                                        ],
                                      },
                                    },
                                  });
                                },
                              });
                            }}
                            key={seed.id}
                            seed={seed}
                          ></SeedCard>
                        );
                      })
                    ) : (
                      <Our.Text tag="h2" weight="400">
                        No seeds found
                      </Our.Text>
                    )}
                  </LoaderContainer>
                </Our.Box>
              </Our.Box>
            </Our.GridItem>
            <Our.GridItem sm={viewMode === "map" ? "12" : "none"} md="7">
              <div ref={mapRef}></div>
            </Our.GridItem>
          </Our.Grid>
        </>
      </Our.Container>
      <Our.Modal
        open={showFilters}
        onToggle={(e) => setShowFilters(e.target.open)}
      >
        <Our.Text slot="header-start" inline tag="h3">
          Filters
        </Our.Text>
        <div slot="header-end">
          <Our.Button rounded type="transparent" size="sm">
            Clear filters
          </Our.Button>
          <Our.Button
            onClick={() => setShowFilters(false)}
            rounded
            size="sm"
            squared
            type="transparent"
          >
            <i className="gg-close"></i>
          </Our.Button>
        </div>

        <Our.Box padding-b="xl">
          <InputFilterSeeds
            onSubmit={(input) => {
              const coords = mapInstance.getCenter();
              getSeeds({
                variables: {
                  filter: {
                    category: {
                      eq: input.category,
                    },
                    sunConditions: {
                      in: input.sunConditions,
                    },
                    soilConditions: {
                      in: input.soilConditions,
                    },
                    geohash: {
                      gte: getGeohashRange(coords.lat, coords.lng).lower,
                      lte: getGeohashRange(coords.lat, coords.lng).upper,
                    },
                  },
                },
              });
              setShowFilters(false);
            }}
          ></InputFilterSeeds>
        </Our.Box>
      </Our.Modal>
    </>
  );
}
