import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { typeOf } from "../utils";

export function useGeoIp() {
  const [location, setLocation] = useState(null);

  async function getGeoIp() {
    const res = await fetch(" https://freegeoip.app/json/");
    const location = await res.json();
    setLocation(location);
  }

  getGeoIp();

  return location;
}

export function useRouteQuery() {
  return new URLSearchParams(useLocation().search);
}

export function useInput({
  initialInput = {},
  onChange = () => {},
  onSubmit = () => {},
  resetOnSubmit = false,
}) {
  function removeTypeAndId(data) {
    const { __typename, id, ...strippedData } = data;
    let res = { ...strippedData };
    Object.keys(data).forEach((key) => {
      if (typeOf(res[key]) === "object") {
        const { __typename, id, ...strippedData } = removeTypeAndId(res[key]);
        res = { ...res, [key]: { ...strippedData } };
      }
    });
    return res;
  }

  const [input, setInput] = useState(removeTypeAndId(initialInput));

  useEffect(() => {
    setInput(removeTypeAndId(initialInput));
  }, [JSON.stringify(initialInput)]);

  function changeInput(newInput) {
    const data = removeTypeAndId(newInput);
    setInput({ ...input, ...data });
    onChange({ ...input, ...data });
  }

  function submitInput() {
    onSubmit(input);
    if (resetOnSubmit) setInput(initialInput);
  }

  return { input, submitInput, changeInput };
}

export default { useGeoIp, useRouteQuery, useInput };
