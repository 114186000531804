import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory, useParams, NavLink } from "react-router-dom";
import {
  ME,
  GET_SEED,
  UPDATE_SEED,
  REMOVE_SEED,
} from "../../graphql/mutations";

import * as Our from "../../components/Core";
import InputSeed from "../../components/InputSeed";
import LoaderContainer from "../../components/LoaderContainer";

export default function EditSeedRoute() {
  const history = useHistory();
  const params = useParams();
  const { data, loading, error } = useQuery(GET_SEED, {
    variables: {
      id: params.id,
    },
  });

  const [updateSeed] = useMutation(UPDATE_SEED);
  const [removeSeed] = useMutation(REMOVE_SEED);

  if (!data) return null;

  return (
    <>
      <Our.Box margin-b="lg">
        <NavLink style={{ display: "flex" }} to="/dashboard/inventory">
          <ion-icon name="arrow-back-outline"></ion-icon>
          Back
        </NavLink>
      </Our.Box>
      <LoaderContainer data={data} loading={loading} error={error}>
        <InputSeed
          onDelete={() =>
            removeSeed({
              variables: {
                id: data.seed.id,
              },
            })
          }
          title="Edit Seed"
          initialState={data.seed}
          onSubmit={(input) => {
            updateSeed({
              variables: {
                id: data.seed.id,
                patch: {
                  ...input,
                },
              },
              refetchQueries: [{ query: ME }],
            }).then((res) => {
              history.push("/dashboard/inventory");
            });
          }}
        ></InputSeed>
      </LoaderContainer>
    </>
  );
}
