import React, { useState, useEffect } from "react";
import * as Our from "./Core";
import enums from "../constants/enums";
import { useInput } from "../hooks";

export default function InputFilterSeeds({
  initialState = {
    category: "",
    sunConditions: enums.sunConditions,
    waterConditions: enums.waterConditions,
    soilConditions: [],
    temperatureRange: { start: 0, end: 40 },
  },
  onChange = () => {},
  resetOnSubmit = false,
  onSubmit = () => {},
  submitButtonText = "Save",
}) {
  const [sunConditionRange, setSunConditionRange] = useState([
    0,
    enums.sunConditions.length - 1,
  ]);

  const { input, changeInput, submitInput } = useInput({
    initialInput: initialState,
    onChange,
    onSubmit,
    resetOnSubmit,
  });

  return (
    <>
      <Our.Container size="xs" center>
        <Our.Box margin-y="lg">
          <Our.Label>What are you looking for?</Our.Label>
          <Our.Select
            value={input.category || "everything"}
            onSelect={(e) => {
              changeInput({
                category: e.target.value === "everything" ? "" : e.target.value,
              });
            }}
            full
          >
            <Our.Option value="everything" label="Everything"></Our.Option>
            {enums.seedCategories.map((option) => (
              <Our.Option
                key={option}
                value={option}
                label={option}
              ></Our.Option>
            ))}
          </Our.Select>
        </Our.Box>
        <Our.Box margin-y="lg">
          <Our.Label>Soil conditions</Our.Label>
          <Our.Select
            full
            multiple
            value={input.soilConditions}
            onSelect={(e) => {
              changeInput({
                soilConditions: e.target.value,
              });
            }}
          >
            <Our.Option value="all" label="All"></Our.Option>
            {enums.soilConditions.map((option) => (
              <Our.Option key={option} value={option}></Our.Option>
            ))}
          </Our.Select>
        </Our.Box>
        <Our.Box>
          <Our.Label>Sun conditions</Our.Label>
          <Our.Range
            sensitivity="0.7"
            labels="Dense shade, Full sun"
            value={sunConditionRange}
            max={enums.sunConditions.length - 1}
            min="0"
            step="1"
            onChange={(e) => {
              const [start, end] = e.target.value;
              const conditions = enums.sunConditions.filter((c, i) => {
                return i >= start && i <= end;
              });
              changeInput({ sunConditions: conditions });
              setSunConditionRange([start, end]);
            }}
          ></Our.Range>
        </Our.Box>
        <Our.Box margin-y="lg">
          <Our.Label>Water requirement</Our.Label>
          <Our.Range
            full
            labels="Low,Medium,High"
            sensitivity="0.7"
            value="0,4"
            max="4"
            min="0"
            step="1"
          ></Our.Range>
        </Our.Box>
        <Our.Box margin-y="lg">
          <Our.Label>Temperature</Our.Label>
          <Our.Range
            full
            labels="0,10,20,30,40"
            sensitivity="0.7"
            value="0,40"
            max="40"
            min="-0"
            step="5"
          ></Our.Range>
        </Our.Box>
        <Our.Button full type="primary" onClick={() => submitInput()}>
          {submitButtonText}
        </Our.Button>
      </Our.Container>
    </>
  );
}
