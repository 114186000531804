import { gql } from "@apollo/client";

export const typeDef = gql`
  input RangeInput {
    start: Int!
    end: Int!
  }

  input IntFilter {
    gt: Int
    gte: Int
    lt: Int
    lte: Int
    eq: Int
    neq: Int
  }

  input StringFilter {
    eq: String
    neq: String
    in: [String]
    gt: String
    gte: String
    lt: String
    lte: String
  }
`;
