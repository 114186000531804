import { gql } from "@apollo/client";

export const ME = gql`
  query Me {
    me {
      id
      selectedPlace {
        id
        name
        seeds {
          id
          name
          count
          isPublic
          waterConditions
          soilConditions
          sunConditions
        }
      }
      places {
        id
        name
        address
      }
      savedSeeds {
        id
        name
        count
        place {
          address
          id
        }
      }
    }
  }
`;

export const SET_SELECTED_PLACE = gql`
  mutation SetSelectedPlace($patch: UpdateUserInput) {
    updateUser(patch: $patch) {
      id
      selectedPlace {
        id
        name
        seeds {
          id
          name
        }
      }
    }
  }
`;

export const REMOVE_SEED_BOOKMARK = gql`
  mutation removeSeedBookmark($id: ID!) {
    removeSeedBookmark(id: $id) {
      id
    }
  }
`;

export const GET_PLACE = gql`
  query GetPlace($id: ID!) {
    place(id: $id) {
      id
      name
      seeds {
        name
        id
        count
        isPublic
        waterConditions
        soilConditions
        sunConditions
      }
    }
  }
`;

export const REMOVE_SEED = gql`
  mutation RemoveSeed($id: ID!) {
    removeSeed(id: $id) {
      id
    }
  }
`;

export const ADD_PLACE = gql`
  mutation AddPlace($input: AddPlaceInput!) {
    addPlace(input: $input) {
      id
    }
  }
`;

export const REMOVE_PLACE = gql`
  mutation RemovePlace($id: ID!) {
    removePlace(id: $id) {
      id
    }
  }
`;

export const ADD_SEED = gql`
  mutation AddSeed($input: AddSeedInput) {
    addSeed(input: $input) {
      id
    }
  }
`;

export const GET_SEED = gql`
  query GetSeed($id: ID!) {
    seed(id: $id) {
      id
      name
      count
      soilConditions
      waterConditions
      sunConditions
      temperatureRange {
        start
        end
      }
      isPublic
      category
    }
  }
`;

export const UPDATE_SEED = gql`
  mutation UpdateSeed($id: ID!, $patch: UpdateSeedInput) {
    updateSeed(id: $id, patch: $patch) {
      id
    }
  }
`;

export const SEEDS = gql`
  query Seeds($filter: SeedFilter!) {
    seeds(filter: $filter) {
      id
      name
      place {
        lat
        lng
        address
      }
    }
  }
`;

export const ADD_SEED_BOOKMARK = gql`
  mutation addSeedBookmark($id: ID!) {
    addSeedBookmark(id: $id) {
      id
    }
  }
`;

export const SIGN_IN = gql`
  mutation signIn($input: SignInInput!) {
    signIn(input: $input) {
      id
    }
  }
`;

export const SIGN_UP = gql`
  mutation signUp($input: SignUpInput!) {
    signUp(input: $input) {
      id
    }
  }
`;
