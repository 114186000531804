import React from "react";
import * as Our from "../../components/Core";
import { useMutation, useQuery } from "@apollo/client";
import { REMOVE_PLACE, ME } from "../../graphql/mutations";
import { useHistory } from "react-router";

export default function Settings() {
  const history = useHistory();
  const { data: userData } = useQuery(ME);

  const [removePlace] = useMutation(REMOVE_PLACE);

  return (
    <>
      <Our.Box margin-b="lg">
        <Our.Text tag="h1">Add Place</Our.Text>
      </Our.Box>
      <Our.List>
        <Our.ListItem
          onClick={() => {
            removePlace({
              variables: {
                id: userData.me.selectedPlace.id,
              },
              refetchQueries: [{ query: ME }],
            });
            history.push("/dashboard");
          }}
        >
          <ion-icon slot="start" name="trash-outline"></ion-icon>
          Remove place
        </Our.ListItem>
      </Our.List>
    </>
  );
}
