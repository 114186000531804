import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory, NavLink } from "react-router-dom";
import { ME, ADD_SEED } from "../../graphql/mutations";

import * as Our from "../../components/Core";
import InputSeed from "../../components/InputSeed";
import LoaderContainer from "../../components/LoaderContainer";

export default function AddSeedRoute() {
  const history = useHistory();
  const { data: userData } = useQuery(ME);
  const [addSeed] = useMutation(ADD_SEED);

  if (!userData) return null;

  return (
    <>
      <Our.Box margin-b="lg">
        <NavLink style={{ display: "flex" }} to="/dashboard/inventory">
          <i className="gg-chevron-left"></i>
          Back
        </NavLink>
      </Our.Box>
      <InputSeed
        title="Add Seed"
        onSubmit={(input) => {
          addSeed({
            variables: {
              input: {
                ...input,
                placeId: userData.me.selectedPlace.id,
              },
            },
            refetchQueries: [{ query: ME }],
          }).then(() => {
            history.push("/dashboard/inventory");
          });
        }}
      ></InputSeed>
    </>
  );
}
