import React from "react";
import { useMutation } from "@apollo/client";
import { useHistory, NavLink } from "react-router-dom";
import { ME, ADD_PLACE, SET_SELECTED_PLACE } from "../../graphql/mutations";

import * as Our from "../../components/Core";
import InputPlace from "../../components/InputPlace";
import LoaderContainer from "../../components/LoaderContainer";

export default function AddPlaceRoute() {
  const history = useHistory();

  const [addPlace] = useMutation(ADD_PLACE);
  const [setSelectedPlace] = useMutation(SET_SELECTED_PLACE);

  return (
    <>
      <Our.Box margin-b="lg">
        <NavLink style={{ display: "flex" }} to="/dashboard/inventory">
          <i className="gg-chevron-left"></i>
          Back
        </NavLink>
      </Our.Box>
      <InputPlace
        onSubmit={(input) => {
          addPlace({
            variables: { input },
          }).then((res) => {
            const newPlaceId = res.data.addPlace.id;
            setSelectedPlace({
              variables: {
                patch: { selectedPlaceId: newPlaceId },
              },
              refetchQueries: [{ query: ME }],
            });
            history.push("/dashboard");
          });
        }}
      ></InputPlace>
    </>
  );
}
