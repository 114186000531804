import React, { useRef } from "react";
import * as Our from "./Core";
import "./StepInput.css";

export default function StepInput({
  value = 0,
  step = 1,
  max,
  min,
  onChange = () => {},
}) {
  const inputRef = useRef(null);

  return (
    <>
      <Our.Button onClick={() => onChange(value - step)} squared>
        -
      </Our.Button>
      <Our.Input
        className="step"
        ref={inputRef}
        onChange={(e) => onChange(Number(e.target.value))}
        value={value}
        step={step}
        type="number"
        max={max}
        min={min}
      ></Our.Input>
      <Our.Button squared onClick={() => onChange(value + step)}>
        +
      </Our.Button>
    </>
  );
}
