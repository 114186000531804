import React from "react";
import * as Our from "./Core";
import Spinner from "./Spinner";

export default function LoaderContainer({ error, loading, children }) {
  if (error) return <div>{error.message}</div>;
  if (loading)
    return (
      <Our.Box>
        <Our.Flex justify-content="center" align-items="center">
          <Spinner></Spinner>
        </Our.Flex>
      </Our.Box>
    );
  return children;
}
