import React from "react";
import * as Our from "../../components/Core";
import { useQuery, useMutation } from "@apollo/client";
import Spinner from "../../components/Spinner";
import { ME } from "../../graphql/mutations";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";

export default function MyAccount() {
  const history = useHistory();

  const {
    data: userData,
    loading: loadingUserData,
    error: userError,
  } = useQuery(ME);

  return (
    <>
      <Our.Flex justify-content="between" align-items="center">
        <Our.Text inline weight="500" tag="h1">
          Inventory
        </Our.Text>
        <Our.Button
          type="primary"
          size="sm"
          onClick={() => {
            history.push("/dashboard/add-seed");
          }}
        >
          <ion-icon slot="start" name="add-outline"></ion-icon>
          Add seed
        </Our.Button>
      </Our.Flex>

      <Our.Box margin-y="lg">
        {userError && (
          <Our.Box margin-y="md">
            <Our.Text color="danger">{userError.message}</Our.Text>
          </Our.Box>
        )}
        {loadingUserData && (
          <Our.Flex justify-content="center" align-items="center">
            <Spinner></Spinner>
          </Our.Flex>
        )}
        {userData && (
          <Our.Box>
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Category</th>
                    <th>Price</th>
                    <th>Public</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.me.selectedPlace?.seeds.map((seed) => {
                    return (
                      <tr key={seed.id}>
                        <td data-label="Name">
                          <Our.Text full tag="h2" color="font-light" look="p">
                            {seed.name}
                          </Our.Text>
                        </td>
                        <td data-label="Category">
                          <Our.Text full color="font-light" tag="p" look="p">
                            {seed.category || "None"}
                          </Our.Text>
                        </td>
                        <td data-label="Price">
                          <Our.Text full color="font-light" tag="p" look="p">
                            {seed.price || "Free"}
                          </Our.Text>
                        </td>
                        <td data-label="Public">
                          <Our.Text full tag="p" look="p">
                            {seed.isPublic ? "Yes" : "No"}
                          </Our.Text>
                        </td>
                        <td data-label="Actions">
                          <Our.Text color="primary">
                            <NavLink to={`/dashboard/edit-seed/${seed.id}`}>
                              Edit
                            </NavLink>
                          </Our.Text>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Our.Box>
        )}
      </Our.Box>
    </>
  );
}
