import { gql } from "@apollo/client";

export const typeDef = gql`
  extend type Query {
    place(id: ID!): Place!
  }

  extend type Mutation {
    addPlace(input: AddPlaceInput!): Place!
    updatePlace(id: ID!, patch: UpdatePlaceInput!): Place!
    removePlace(id: ID!): RemovePlacePayload!
  }

  type Place {
    id: ID!
    name: String!
    address: String
    lat: Float!
    lng: Float!
    geohash: String!
    countryCode: String!
    seeds: [Seed!]
    user: User!
  }

  input AddPlaceInput {
    name: String!
    address: String!
    lat: Float!
    lng: Float!
    geohash: String!
    countryCode: String!
    userId: ID
  }

  input UpdatePlaceInput {
    name: String
    address: String
    lat: Float
    lng: Float
    geohash: String
    countryCode: String
    userId: ID
  }

  type RemovePlacePayload {
    id: ID!
  }
`;

export const resolvers = {
  Query: {
    place: async (root, args, ctx) => {
      try {
        const place = await ctx.db.collection("places").doc(args.id).get();
        return { id: args.id, ...place.data() };
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  Mutation: {
    addPlace: async (root, args, ctx) => {
      try {
        var docRef = await ctx.db.collection("places").add({
          ...args.input,
          userId: args.input.userId || ctx.auth.currentUser.uid,
        });
        return { id: docRef.id, ...args.input };
      } catch (e) {
        throw new Error(e);
      }
    },
    updatePlace: async (root, args, ctx) => {
      try {
        await ctx.db
          .collection("places")
          .doc(args.id)
          .update({ ...args.patch });
        const seedRef = await ctx.db.collection("places").doc(args.id).get();
        return { id: seedRef.id, ...seedRef.data() };
      } catch (e) {
        throw new Error(e);
      }
    },
    removePlace: async (root, args, ctx) => {
      try {
        await ctx.db.collection("places").doc(args.id).delete();

        const seedsSnap = await ctx.db
          .collection("seeds")
          .where("placeId", "==", args.id)
          .get();

        // Delete all seeds associated to that place
        seedsSnap.forEach((seed) => {
          seed.ref.delete();
        });
        return { id: args.id };
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  Place: {
    seeds: async (place, args, ctx) => {
      try {
        const items = [];
        const itemsRef = await ctx.db
          .collection("seeds")
          .where("placeId", "==", place.id)
          .get();
        itemsRef.forEach((item) => {
          items.push({ id: item.id, ...item.data() });
        });
        return items;
      } catch (e) {
        throw new Error(e);
      }
    },
  },
};
