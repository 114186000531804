import React, { useState, useEffect } from "react";
import geohash from "ngeohash";
import * as Our from "./Core";
import MapSearchInput from "./MapSearchInput";
import { useInput } from "../hooks";

export default function InputPlace({
  initialState = {
    name: "",
    address: "",
    lat: 0,
    lng: 0,
    geohash: "",
    countryCode: "",
  },
  resetOnSubmit = false,
  onSubmit = () => {},
  onChange = () => {},
  submitButtonText = "Save",
}) {
  const { input, changeInput, submitInput } = useInput({
    initialInput: initialState,
    onChange,
    onSubmit,
    resetOnSubmit,
  });

  return (
    <>
      <Our.Box margin-b="lg">
        <Our.Text tag="h1">Add Place</Our.Text>
      </Our.Box>
      <Our.Box margin-y="md">
        <Our.Label>Name</Our.Label>
        <Our.Input
          value={input.name}
          onChange={(e) => changeInput({ name: e.target.value })}
          full
          placeholder="Bob's Farm"
        ></Our.Input>
      </Our.Box>
      <Our.Box margin-y="md">
        <Our.Label>Place</Our.Label>
        <MapSearchInput
          input={input.place?.address}
          onSelect={(place) => {
            changeInput({
              address: place.address,
              lat: place.lat,
              lng: place.lng,
              geohash: geohash.encode(place.lat, place.lng),
            });
          }}
        ></MapSearchInput>
      </Our.Box>
      <Our.Box margin-y="xl">
        <Our.Button full type="primary" onClick={() => submitInput()}>
          {submitButtonText}
        </Our.Button>
      </Our.Box>
    </>
  );
}
