import React, { useState } from "react";
import { useSession, auth } from "../services/firebase";

import * as Our from "../components/Core";
import Header from "../components/Header";
import enums from "../constants/enums";
import MapSearchInput from "../components/MapSearchInput";
import { useHistory } from "react-router-dom";

export default function Home() {
  const user = useSession();
  const history = useHistory();

  const [isFindingLocation, setIsFindingLocation] = useState(false);

  function handleSelectPlace(place) {
    history.push(
      `/search?q=${place.address}&lat=${place.lat}&lng=${place.lng}`
    );
  }

  function findMe() {
    if (navigator.geolocation) {
      setIsFindingLocation(true);
      navigator.geolocation.getCurrentPosition((pos) => {
        setIsFindingLocation(false);
        const lat = pos.coords.latitude;
        const lng = pos.coords.longitude;
        handleSelectPlace({ address: "", lat, lng });
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  return (
    <div id="home">
      <Header
        user={user}
        onSignOut={() => {
          auth.signOut();
        }}
      ></Header>

      <Our.Box style={{ textAlign: "center" }} padding-x="lg" padding-y="xl">
        <Our.Container center size="lg">
          <Our.Box margin-y="xl">
            <Our.Text
              inline
              className="main-heading"
              weight="400"
              look=""
              tag="h1"
            >
              Find seeds that grow in your climate
            </Our.Text>
          </Our.Box>
        </Our.Container>

        <Our.Container center size="xs">
          <MapSearchInput
            size="xl"
            full
            autofocus
            placeholder="Search for place"
            onSelect={(place) => {
              handleSelectPlace(place);
            }}
          ></MapSearchInput>

          <Our.Box margin-y="md">or</Our.Box>

          <Our.Button
            style={{ minWidth: "200px" }}
            onClick={() => findMe()}
            size="xl"
            type="primary"
          >
            <i
              slot="start"
              className={isFindingLocation ? "gg-spinner" : "gg-pin"}
            ></i>
            Find me
          </Our.Button>
        </Our.Container>
      </Our.Box>
    </div>
  );
}
