import "./Core.css";
import reactifyWc from "../utils/reactify-wc";

export const Checkbox = reactifyWc("core-checkbox");
export const Text = reactifyWc("core-text");
export const Container = reactifyWc("core-container");
export const Select = reactifyWc("core-select");
export const Option = reactifyWc("core-option");
export const Radio = reactifyWc("core-radio");
export const Button = reactifyWc("core-button");
export const Box = reactifyWc("core-box");
export const Toggle = reactifyWc("core-toggle");
export const Modal = reactifyWc("core-modal");
export const Tabs = reactifyWc("core-tabs");
export const Tab = reactifyWc("core-tab");
export const Grid = reactifyWc("core-grid");
export const GridItem = reactifyWc("core-grid-item");
export const Accordion = reactifyWc("core-accordion");
export const Flex = reactifyWc("core-flex");
export const Input = reactifyWc("core-input");
export const Label = reactifyWc("core-label");
export const Range = reactifyWc("core-range");
export const Overlay = reactifyWc("core-overlay");
export const List = reactifyWc("core-list");
export const ListItem = reactifyWc("core-list-item");
export const Slider = reactifyWc("core-slider");
export const SliderItem = reactifyWc("core-slider-item");
