import { gql } from "@apollo/client";

export const typeDef = gql`
  type User {
    id: ID!
    places: [Place!]
    selectedPlace: Place
    savedSeeds: [Seed!]
    savedPlaces: [Place!]
    savedUsers: [User!]
  }

  input UpdateUserInput {
    countryCode: String
    selectedPlaceId: ID
    savedSeeds: [ID]
  }

  input SignInInput {
    email: String!
    password: String!
  }

  input SignUpInput {
    email: String!
    password: String!
  }

  extend type Query {
    me: User!
    user(id: ID!): User!
  }

  extend type Mutation {
    signIn(input: SignInInput!): User!
    signUp(input: SignUpInput!): User!
    updateUser(id: ID, patch: UpdateUserInput): User!
    addSeedBookmark(id: ID!): Seed!
    removeSeedBookmark(id: ID!): Seed!
  }
`;

export const resolvers = {
  Query: {
    me: async (root, args, ctx) => {
      try {
        const user = await ctx.db
          .collection("users")
          .doc(ctx.auth.currentUser.uid)
          .get();
        return { id: ctx.auth.currentUser.uid, ...user.data() };
      } catch (e) {
        throw new Error(e);
      }
    },
    user: async (root, args, ctx) => {
      try {
        const user = await ctx.db.collection("users").doc(args.id).get();
        return { id: args.id, ...user.data() };
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  Mutation: {
    signIn: async (root, args, ctx) => {
      try {
        const { user } = await ctx.auth.signInWithEmailAndPassword(
          args.input.email,
          args.input.password
        );
        const userRef = await ctx.db.collection("users").doc(user.uid).get();
        return { id: user.uid, ...userRef.data() };
      } catch (e) {
        throw new Error(e);
      }
    },
    signUp: async (root, { input }, ctx) => {
      try {
        // create user in firebase
        const { user } = await ctx.auth.createUserWithEmailAndPassword(
          input.email,
          input.password
        );

        // add default place record
        const createdPlace = await ctx.db.collection("places").add({
          name: "My place",
          address: "",
          lng: null,
          lat: null,
          userId: user.uid,
        });

        // add user record
        await ctx.db
          .collection("users")
          .doc(user.uid)
          .set({ email: input.email, selectedPlaceId: createdPlace.id });

        return { id: user.uid };
      } catch (e) {
        throw new Error(e);
      }
    },
    addSeedBookmark: async (root, args, ctx) => {
      try {
        await ctx.db
          .collection("users")
          .doc(ctx.auth.currentUser.uid)
          .set(
            {
              savedSeeds: ctx.firestore.FieldValue.arrayUnion(args.id),
            },
            { merge: true }
          );

        const seed = await ctx.db.collection("seeds").doc(args.id).get();

        return { id: seed.id, ...seed.data() };
      } catch (e) {
        throw new Error(e);
      }
    },
    removeSeedBookmark: async (root, args, ctx) => {
      try {
        await ctx.db
          .collection("users")
          .doc(ctx.auth.currentUser.uid)
          .set(
            {
              savedSeeds: ctx.firestore.FieldValue.arrayRemove(args.id),
            },
            { merge: true }
          );

        const seed = await ctx.db.collection("seeds").doc(args.id).get();

        return { id: seed.id, ...seed.data() };
      } catch (e) {
        throw new Error(e);
      }
    },
    updateUser: async (root, args, ctx) => {
      try {
        const userId = args.id || ctx.auth.currentUser.uid;
        await ctx.db
          .collection("users")
          .doc(userId)
          .set({ ...args.patch }, { merge: true });
        const userRef = await ctx.db.collection("users").doc(userId).get();
        return { id: userId, ...userRef.data() };
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  User: {
    selectedPlace: async (user, args, ctx) => {
      try {
        if (!user.selectedPlaceId) return null;
        const item = await ctx.db
          .collection("places")
          .doc(user.selectedPlaceId)
          .get();
        // TODO: Why do we get item.id even if the place does not exist anymore
        if (!item.data()) return null;
        return { id: item.id, ...item.data() };
      } catch (e) {
        throw new Error(e);
      }
    },
    places: async (user, args, ctx) => {
      try {
        const items = [];
        const itemsRef = await ctx.db
          .collection("places")
          .where("userId", "==", user.id)
          .get();
        itemsRef.forEach((item) => {
          items.push({ id: item.id, ...item.data() });
        });
        return items;
      } catch (e) {
        throw new Error(e);
      }
    },
    savedSeeds: async (user, args, ctx) => {
      try {
        if (!user.savedSeeds) return [];

        const promises = [];
        const items = [];

        user.savedSeeds.forEach((id) => {
          promises.push(ctx.db.collection("seeds").doc(id).get());
        });

        const seeds = await Promise.all(promises);

        seeds.forEach((item) => {
          if (item.data()) {
            items.push({ id: item.id, ...item.data() });
          }
        });

        return items;
      } catch (e) {
        throw new Error(e);
      }
    },
  },
};
